.terms_a_cond_container {
    padding: 30px;
  }
  
  .terms_header {
    color: #c28e21;
    font-family: "Playfair Display", Sans-serif;
    font-size: 25px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 34px;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .terms_content {
    padding: 0px 25px;
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 10px;
  }
  
  .sub_head {
    font-size: 16px;
    padding: 0px 25px;
    font-weight: 700;
    margin-bottom: 12px;
  }
  