.footer_container {
  background: #1b1b5d;
  box-shadow: 0px 4px 20px rgba(0, 108, 207, 0.2);

  align-content: center;
  padding: 30px;
}

.footer_main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 25px;
}

.footer_box {
  width: 25%;
}

.footer_head {
  color: rgba(194, 142, 33, 1);
  font-size: 18px;
  font-weight: 600;
  font-family: "Playfair Display", serif;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 18px;
}

.footer_logo {
  margin-bottom: 15px;
}

.footer_content {
  color: rgba(255, 255, 255, 1);
  font-size: 13px;
  font-weight: 600;
  font-family: "Barlow", sans-serif !important;
  line-height: 16px;
  text-transform: uppercase;
}

.footer_menulinks {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer_menulinks li {
  color: rgba(255, 255, 255, 1);
  font-size: 13px;
  font-weight: 600;
  font-family: "Barlow", sans-serif !important;
  line-height: 16px;
  padding-bottom: 18px;
  cursor: pointer;
  text-transform: uppercase;
}

.footer_contact {
  list-style: none;
}

.footer_contact li {
  color: rgba(255, 255, 255, 1) !important;
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 10px;
  font-family: "Barlow", sans-serif !important;
  line-height: 16px;
}

.footer_contact li a {
  color: rgba(255, 255, 255, 1) !important;
  text-decoration: none;
}

.footer_contact li img {
  margin-right: 10px;
}

.footer_socialhead {
  font-size: 13px;
  font-weight: 600;
  font-family: "Barlow", sans-serif !important;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
  margin-top: 12px;
}

.footer_social {
  list-style: none;
  margin-top: 10px !important;
  display: flex;
}

.footer_social li {
  margin-right: 10px;
}

.footer_map iframe {
  border-radius: 5px;
}

.footerbtm_border {
  border-top: 1px dashed rgba(255, 255, 255, 1);
  margin-bottom: 20px;
}

.footer_btm {
  color: rgba(194, 142, 33, 1);
  font-size: 13px;
  font-weight: 600;
  font-family: "Barlow", sans-serif !important;
  text-align: center;
  text-transform: uppercase;
}

.footer_btm a {
  color: rgba(255, 255, 255, 1) !important;
  cursor: pointer;
  text-decoration: none;
}
